<template>
  <div class="admin-dashboard">
    <h1 class="funny-title">
      Hello, <span class="friend-name">{{ name }}</span
      >!
    </h1>
    <div class="crazy-message">
      <p>
        Remélem szipi-szupi napod van és ma nem tervezel senkit sem lábon lőni!
        <br />Ha esetleg mégis, remélem, azok a golyók engem elkerülnek.
      </p>
    </div>
    <div class="random-images">
      <img
        :src="require('@/assets/gun.jpg')"
        alt="Gun"
        class="random-image gun2"
      />
      <img
        :src="require('@/assets/cute-alpaca.jpg')"
        alt="Alpaca"
        class="alpaca"
      />
      <img
        :src="require('@/assets/gun2.jpg')"
        alt="Gun"
        class="random-image gun1"
      />
    </div>
    <div class="rolling-tesla">
      <img :src="require('@/assets/tesla.png')" alt="Tesla" class="tesla" />
    </div>
    <div class="spinning-cat">
      <img
        :src="require('@/assets/spinningcat.gif')"
        alt="Spinning Cat"
        class="cat"
      />
    </div>
    <div class="leaning-cat">
      <img
        :src="require('@/assets/leaningcat.png')"
        alt="Leaning Cat"
        class="cat"
      />
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const name = ref(
  "Tardi Mindent Is Tudó Kedves Bájos Szuper Pornó Péter Elnök Úr"
);
</script>

<style scoped>
.admin-dashboard {
  text-align: center;
  margin: 20px;
  position: relative;
}

.funny-title {
  font-size: 3em;
  color: #ff66b2;
  animation: shake 0.5s ease-in-out infinite alternate;
}

.friend-name {
  font-weight: bold;
  color: #ff66b2;
  animation: rainbowText 3s linear infinite;
}

.crazy-message {
  margin-top: 20px;
  font-size: 1.5em;
  color: #33cc33;
  animation: bounce 1s ease-in-out infinite, colorChange 5s linear infinite;
  margin-bottom: 50px;
}

.random-images {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.random-image {
  max-height: 200px;
  width: auto;
  margin: 50px;
  border-radius: 10px;
  animation: spin 2s linear infinite;
}

.gun1 {
  animation: spin 2s linear infinite;
}

.gun2 {
  animation: spinReverse 2s linear infinite;
}

.alpaca {
  animation: moveInAndOut 4s linear infinite;
  width: 300px;
}

.rolling-tesla {
  margin-top: 30px;
  transform: translate(-100%, -50%);
  animation: rollInAndOut 6s linear infinite;
}

.tesla {
  height: 200px;
  width: auto;
}

.spinning-cat {
  position: absolute;
  top: 60px;
  right: 0;
  margin: 20px;
}

.leaning-cat {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  animation: leanInAndOut 10s ease-in-out infinite;
}

.cat {
  width: 200px;
  height: auto;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(10px);
  }
}

@keyframes rainbowText {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinReverse {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

@keyframes moveInAndOut {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

@keyframes rollInAndOut {
  0% {
    transform: translateX(-100%);
  }
  25% {
    transform: translateX(-20%);
  }
  30% {
    transform: translateX(-10%);
  }
  40% {
    transform: translateX(0%);
  }
  70% {
    transform: translateX(0%);
  }
  70.01% {
    transform: translateX(0%);
    animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes colorChange {
  0% {
    color: #33cc33;
  }
  50% {
    color: #ff6666;
  }
  100% {
    color: #33cc33;
  }
}

@keyframes leanInAndOut {
  0% {
    transform: translateX(100%) rotate(-10deg);
  }
  30% {
    transform: translateX(100%) rotate(-10deg);
  }
  50% {
    transform: translateX(50%) rotate(-20deg);
  }
  70% {
    transform: translateX(50%) rotate(-20deg);
  }
  90% {
    transform: translateX(100%) rotate(-10deg);
  }
  100% {
    transform: translateX(100%) rotate(-10deg);
  }
}
</style>
